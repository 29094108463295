import React, { Component } from 'react';
import axios from 'axios';
import Layout from '../compontents/layout';
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

export default class All extends Component {
    constructor(props) {
        super(props);


        this.state = {
            data: [],
            title: "",
            param1: "",
            param2: "",
            param3: "",
            param4: "",
            param5: "",
            param6: "",
            title: "",
            offset: "",
            search: "",
            deleteAlert: false,
            deleteID: ""
        };
        this.handleSearch = this.handleSearch.bind(this);

    }

    handleSearch(event) {
        this.setState({ search: event.target.value });
        this.getData(0, event.target.value, this.props.controller);
    }

    componentDidMount() {
        this.getData(0, "", this.props.controller);
    }

    componentWillReceiveProps(nextProps) {
        this.refresh(nextProps);
    }

    refresh(props) {
        this.getData(0, "", props.controller);
    }

    getData(offset, search, controller) {
        this.setState({ offset: offset });

        axios.post('https://api.skroty.pl/api/admin/' + controller + '/all',
            { offset: offset, search: search },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(results => {

                let result = results.data;
                console.log(result);
                this.setState({
                    data: result.data,
                    param1: result.param1,
                    param2: result.param2,
                    param3: result.param3,
                    param4: result.param4,
                    param5: result.param5,
                    param6: result.param6,
                    title: result.title
                });
            })


    }

    deleteData(id) {
        this.setState({ deleteAlert: false });
        if (this.state.deleteID != "") {
            axios.delete('https://api.skroty.pl/api/admin/' + this.props.controller + '/' + id,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(results => {
                    let result = results.data;
                    this.getData(this.state.offset, "", this.props.controller);
                })
        }
    }




    render() {
        return (
            <Layout>
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row page-title-header">
                            <div className="col-12">
                                <div className="page-header">
                                </div>
                            </div>
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">{this.state.title}</h4>
                                        <p className="card-description">
                                            <div className="form-group">
                                                <input type="search" onChange={this.handleSearch} className="form-control" placeholder="Wyszukaj" />
                                            </div>
                                        </p>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    {this.state.param1 ? <th style={{ width: "10px" }}> {this.state.param1} </th> : ""}
                                                    {this.state.param2 ? <th style={{ width: "10px" }}> {this.state.param2} </th> : ""}
                                                    {this.state.param3 ? <th> {this.state.param3} </th> : ""}
                                                    {this.state.param4 ? <th> {this.state.param4} </th> : ""}
                                                    {this.state.param5 ? <th> {this.state.param5} </th> : ""}
                                                    {this.state.param6 ? <th> {this.state.param6} </th> : ""}
                                                    <th>Edytuj
                                       Usuń</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {this.state.data.map((x) => {
                                                    return (
                                                        <tr>
                                                            <td style={{ width: "10px" }}> {x.param1} </td>
                                                            <td style={{ width: "10px" }}> {x.param2.slice(0, 45)} {x.param2.length > 45 ? ".." : ""}</td>
                                                            <td> {x.param3.slice(0, 45)} {x.param3.length > 45 ? ".." : ""} </td>
                                                            <td> {x.param4}</td>
                                                            {this.state.param5 ? <td>{x.param5}</td> : ""}
                                                            {this.state.param6? <td>{x.param6}</td> : ""}
                                                       
                                                            <td> <Link to={"/" + this.props.controller + "/edit/" + x.param1}>✏</Link> |  <button onClick={(e) => { this.setState({ deleteAlert: true, deleteID: x.param1 }) }}>X</button>  </td>
                                                        </tr>
                                                    )
                                                })}


                                            </tbody>
                                        </table>
                                        <p><button onClick={(e) => { this.getData(this.state.offset - 1, this.state.search, this.props.controller) }}>Poprzednia</button><button onClick={(e) => { this.getData(this.state.offset + 1, this.state.search, this.props.controller) }}>Następna</button></p>

                                        <SweetAlert
                                            title={"Czy na pewno chcesz usunac?"}
                                            onConfirm={(e) => { this.deleteData(this.state.deleteID) }}
                                            onCancel={(e) => this.setState({ deleteAlert: false, deleteID: "" })}
                                            show={this.state.deleteAlert}
                                        />
                                    </div>
                                </div>
                            </div>   </div>   </div>  </div>
            </Layout>
        );
    }
}