import React from 'react';
import { Link } from 'react-router-dom';

export default function StartArea() {
     return (
         
          <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
              <li className="nav-item nav-profile">
              </li>

              <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Artykuły</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                    <Link className="nav-link" to="/content/all">Wszystkie</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/content/add">Dodaj</Link>
                    </li>
                  </ul>
                </div>
              </li>		  
              
	  
      
              <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Shortcody</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                    <Link className="nav-link" to="/shortcode/all">Wszystkie</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/shortcode/add">Dodaj</Link>
                    </li>
                  </ul>
                </div>
              </li>	

              <li className="nav-item">
                <a className="nav-link" data-toggle="collapse" aria-expanded="true" aria-controls="ui-basic">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Skróty</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse show" id="ui-basic">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                    <Link className="nav-link" to="/highlight/all">Wszystkie</Link>
                    </li>
                  </ul>
                </div>
              </li>
       
            
            </ul>
          </nav>


     );
}