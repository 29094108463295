import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';
import All from './pages/All';
import Edit from './pages/Edit';
import Login from './pages/Login';


class App extends Component {

  render() {
    return (
      <Router>
        <div className="App">

          <Switch>
          
          <Route exact path="/"> <All controller={"content"} /> </Route>
          <Route exact path="/login"> <Login info={"login"} /> </Route>
		  
			<Route exact path="/highlight/all"> <All controller={"highlight"} /> </Route>
            <Route exact path="/highlight/:id" render = {props => <Edit {...props} controller={"highlight"} type={"edit"} /> } />
            <Route exact path="/highlight/add" render = {props => <Edit {...props} controller={"highlight"} type={"add"} /> } />
			
			<Route exact path="/event/all"> <All controller={"event"} /> </Route>
            <Route exact path="/event/:id" render = {props => <Edit {...props} controller={"event"} type={"edit"} /> } />
            <Route exact path="/event/add" render = {props => <Edit {...props} controller={"event"} type={"add"} /> } />
			
			<Route exact path="/cat/all"> <All controller={"cat"} /> </Route>
      <Route exact path="/cat/edit/:id" render = {props => <Edit {...props} controller={"cat"} type={"edit"} /> } />
       <Route exact path="/cat/add" render = {props => <Edit {...props} controller={"cat"} type={"add"} /> } />

			<Route exact path="/team/all"> <All controller={"team"} /> </Route>
            <Route exact path="/team/:id" render = {props => <Edit {...props} controller={"team"} type={"edit"} /> } />
            <Route exact path="/team/add" render = {props => <Edit {...props} controller={"team"} type={"add"} /> } />


			<Route exact path="/country/all"> <All controller={"country"} type={"add"} /> </Route>
            <Route exact path="/country/edit/:id" render = {props => <Edit {...props} controller={"country"} type={"edit"} /> } />
            <Route exact path="/country/add" render = {props => <Edit {...props} controller={"country"} type={"add"} /> } />
         
		 	<Route exact path="/player/all"> <All controller={"player"} type={"add"} /> </Route>
            <Route exact path="/player/edit/:id" render = {props => <Edit {...props} controller={"player"} type={"edit"} /> } />
            <Route exact path="/player/add" render = {props => <Edit {...props} controller={"player"} type={"add"} /> } />
			
			<Route exact path="/content/all"> <All controller={"content"} type={"add"} /> </Route>
            <Route exact path="/content/edit/:id" render = {props => <Edit {...props} controller={"content"} type={"edit"} /> } />
            <Route exact path="/content/add" render = {props => <Edit {...props} controller={"content"} type={"add"} /> } />
			
			<Route exact path="/bookmaker/all"> <All controller={"bookmaker"} type={"add"} /> </Route>
            <Route exact path="/bookmaker/edit/:id" render = {props => <Edit {...props} controller={"bookmaker"} type={"edit"} /> } />
            <Route exact path="/bookmaker/add" render = {props => <Edit {...props} controller={"bookmaker"} type={"add"} /> } />
			
			      <Route exact path="/bonus/all"> <All controller={"bonus"} type={"add"} /> </Route>
            <Route exact path="/bonus/edit/:id" render = {props => <Edit {...props} controller={"bonus"} type={"edit"} /> } />
            <Route exact path="/bonus/add" render = {props => <Edit {...props} controller={"bonus"} type={"add"} /> } />

            <Route exact path="/shortcode/all"> <All controller={"shortcode"} type={"add"} /> </Route>
            <Route exact path="/shortcode/edit/:id" render = {props => <Edit {...props} controller={"shortcode"} type={"edit"} /> } />
            <Route exact path="/shortcode/add" render = {props => <Edit {...props} controller={"shortcode"} type={"add"} /> } />



          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;